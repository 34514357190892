
let photo_observer = new IntersectionObserver((entries, observer) => {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			entry.target.classList.add('active');
		} else {
			// entry.target.classList.remove('active');
		}
	});
}, { rootMargin: "0px 0px -25% 0px" });
document.querySelectorAll('.fade-in').forEach(photo => { photo_observer.observe(photo) });


let underline_observer = new IntersectionObserver((entries, observer) => {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			entry.target.classList.add('active');
		} else {
			// entry.target.classList.remove('active');
		}
	});
}, { rootMargin: "0px 0px -100px 0px" });
document.querySelectorAll('.underline').forEach(photo => { underline_observer.observe(photo) });

const nav_open = document.querySelectorAll('.menu-open');
const nav_open_main = document.querySelector('#menu-open');
const nav_close = document.querySelector('#menu-close');
const nav = document.querySelector('#main-nav');

nav_open.forEach(trigger => {
	trigger.addEventListener('click', () => {
		nav.classList.add('active');
		nav_close.focus();
	})
});

nav_close.addEventListener('click', () => {
	nav.classList.remove('active');
	nav_open_main.focus();
})


const nav_toggle = document.querySelectorAll('.nav-toggle');

nav_toggle.forEach(trigger => {
	trigger.addEventListener('click', () => {
		console.log(trigger);
		nav_toggle.forEach(trigger => {
			trigger.classList.remove('active');
		});
		trigger.classList.add('active');
	})
});

const active_nav_item = document.querySelector('.nav-list a.active');

if(active_nav_item) {
	const parent_group = active_nav_item.closest('.nav-item');
	if(parent_group) {
		const trigger = parent_group.querySelector('.nav-toggle');
		if(trigger) {
			trigger.click();
		}
	}
}



const caption_toggle = document.querySelectorAll('.expand-caption');

caption_toggle.forEach(trigger => {
	trigger.addEventListener('click', () => {

		const parent_group = trigger.closest('.photo');
		if(parent_group) {
			const expanded_caption = parent_group.querySelector('.expanded-caption');
			if(expanded_caption) {
				expanded_caption.classList.toggle('active');
			}
		}
	})
});